<template>
  <div class="content">
    <FormLogin msg="Welcome to Your Vue.js App" v-if="data.route == 'form-login'"></FormLogin>
    <iframe src="https://herrajesmagnum.com.ar/cotizadorapp" frameborder="0" style="width: 100%;height: 100vh;" v-if="data.route == 'home'"></iframe>
  </div>
  <InstallPWA />
</template>

<script>
import FormLogin from './components/FormLogin.vue'
import InstallPWA from './components/InstallPWA.vue'

export default {
  name: 'App',
  components: {
    FormLogin,
    InstallPWA
  },
  data () {
    return {
      data: {
        route: 'form-login',
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.content {
  min-height: 100vh;
}
body {
  padding: 0;
  margin: 0;
}
</style>
