<template>
  <div v-if="showInstall" class="install-pwa">
  <div class="install-pwa__text">¿Deseas instalar la aplicación en tu dispositivo?</div>
    <button @click="installPWA" class="install-pwa__btn install-pwa__btn--install">
      Instalar!
    </button>
    <button @click="dismissPrompt" class="install-pwa__btn install-pwa__btn--dismiss">
      No, gracias
    </button>
  </div>
</template>

<script>
export default {
  name: 'InstallPWA',
  data () {
    return {
      showInstall: false
    }
  },
  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.showInstall = true
    })
  },
  methods: {
    installPWA() {
      // alert('installPWA')
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt')
        } else {
          console.log('User dismissed the A2HS prompt')
        }
        this.installEvent = null
        this.showInstall = false
      })
    },
    dismissPrompt() {
      this.installEvent = null
      this.showInstall = false
    },
    installPWA2() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service-worker.js')
          .then(registration => {
            console.log('Service worker registration successful with scope: ', registration.scope);
          })
          .catch(err => {
            console.log('Service worker registration failed: ', err);
          });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.install-pwa {
  background: #2C2A6B;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 15px 30px;
  align-items: center;
  &__text {
    color: #fff;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  &__btn {
    border: 1px solid #fff;
    padding: 9px 18px;
    margin-left: 10px;
    border-radius: 0;
    background: #FFF;
    border-color: #FFF;
    color: #000;
    cursor: pointer;
    &--install {
      background: #CC9833;
      border-color: #CC9833;
      color: #FFF;
    }
  }
}
</style>
