<template>
  <div class="form-login">
    <img alt="Vue logo" src="./../assets/logo.png">
    <label>
      <input type="text" placeholder="Username" @change="setUsername" :value="username" @keyup.enter="goToPassword">
    </label>
    <label>
      <input type="text" placeholder="Password" @change="setPassword" :value="password" ref="password" @keyup.enter="login">
    </label>
    <button @click="login" class="btn">Login</button>
  </div>
</template>

<script>
export default {
  name: 'FormLogin',
  data () {
    return {
      username: '',
      password: ''
    }
  },
  created () {
    setTimeout(() => {
      // change to type password
      this.$refs.password.type = 'password'
    }, 1000)
  },
  methods: {
    login() {
      var url = 'https://herrajesmagnum.com.ar/api/auth-client';
      var data = {
        username: this.username,
        password: this.password
      };

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then( ( response ) => {
        /*
        console.clear();
        console.log('aqui esta el response');
        console.log(response.status);
        */
        if ( response.status == 'success' ) {
          this.$root.data.route = 'home';
        } else {
          alert('Usuario o contraseña incorrectos');
        }
      });
    },
    setUsername(e) {
      // lowercase the username
      this.username = e.target.value.toLowerCase()
    },
    setPassword(e) {
      this.password = e.target.value
    },
    goToPassword() {
      this.password = ''
      this.$refs.password.focus()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.form-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.form-login img {
  margin-bottom: 20px;
}
.form-login label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}
.form-login label input {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  max-width: 100%;
}
.form-login button {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  background: #ccc;
  color: #fff;
  cursor: pointer;
}
</style>
